<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex lg12>
        <v-card>
          <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
          <v-data-table fixed-header height="750px"  
            :headers="headers"
            :items="items"
            :options.sync="pagination"
            :server-items-length="total_rows"
            :footer-props="items_per_page"
            :must-sort="true"
            :loading="loading"
            :mobile-breakpoint="100"
          >
            <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
            <template v-slot:item="{ item }">
              <tr :active="item.selected" @click="item.selected = !item.selected" class="list-clickable">
                <td>{{ item.ID }}</td>
                <td>{{ item.Username }}</td>
                <td>{{ item.FirstName}} {{ item.LastName}}</td>
                <td>{{ item.CustomerCode }}</td>
                <td><a target="blank" :href="'https://zalo.me/' + item.PhoneNumber">{{ item.PhoneNumber }}</a></td>
                <td v-if="(roleEditable || roleCS) && !priceId">
                  <v-layout row wrap>
                    <v-flex lg10>
                      <v-edit-dialog
                        :return-value.sync="item.PriceID"
                        large
                        lazy
                        persistent
                        @save="saveItem({'ID': item.ID, 'ListPrices': item.ListPrices || []})"
                      >
                        <div>{{ item.PriceNames }}</div>
                        <template v-slot:input>
                          <v-autocomplete
                            :items="all_prices"
                            multiple
                            v-model="item.ListPrices"
                            item-text="Name"
                            item-value="ID"
                            clearable
                          ></v-autocomplete>
                        </template>
                      </v-edit-dialog>
                    </v-flex>
                    <v-flex lg2 v-if="item.ListPrices.length > 0">
                      <a :href="'/cai-dat/bang-gia-van-chuyen/' + item.ListPrices[0]" target="blank"><i role="button" medium="" class="v-icon notranslate ma-1 v-icon--link theme--light fa fa-external-link-alt"></i></a>
                    </v-flex>
                  </v-layout>
                </td>
                <td v-else>
                  <v-layout row wrap>
                    <v-flex lg10>
                      {{ item.PriceNames }}
                    </v-flex>
                    <v-flex lg2 v-if="item.ListPrices.length > 0">
                      <a :href="'/cai-dat/bang-gia-van-chuyen/' + item.ListPrices[0]" target="blank"><i role="button" medium="" class="v-icon notranslate ma-1 v-icon--link theme--light fa fa-external-link-alt"></i></a>
                    </v-flex>
                  </v-layout>
                </td>
                <td class="text-end">{{ item.Wallet | currency }}</td>
                <td v-if="roleEditable && !priceId">
                  <v-edit-dialog
                    :return-value.sync="item.CustomerServiceName"
                    large
                    lazy
                    persistent
                    @save="saveItem({'ID': item.ID, 'CustomerServiceID': item.CustomerServiceID})"
                  >
                    <div>{{ item.CustomerServiceName }}</div>
                    <template v-slot:input>
                      <v-autocomplete
                        :items="staffs"
                        v-model="item.CustomerServiceID"
                        item-text="FullName"
                        item-value="ID"
                        v-on:change="item.CustomerServiceName = getStaffName(item.CustomerServiceID)"
                      ></v-autocomplete>
                    </template>
                  </v-edit-dialog>
                </td>
                <td v-else>
                  {{ item.CustomerServiceName }}
                </td>
                <td v-if="roleEditable && !priceId">
                  <v-edit-dialog
                    :return-value.sync="item.SaleName"
                    large
                    lazy
                    persistent
                    @save="saveItem({'ID': item.ID, 'SaleID': item.SaleID})"
                  >
                    <div>{{ item.SaleName }}</div>
                    <template v-slot:input>
                      <v-autocomplete
                        :items="staffs"
                        v-model="item.SaleID"
                        item-text="FullName"
                        item-value="ID"
                        v-on:change="item.SaleName = getStaffName(item.SaleID)"
                      ></v-autocomplete>
                    </template>
                  </v-edit-dialog>
                </td>
                <td v-else>
                  {{ item.SaleName }}
                </td>
                <td class="truncate">
                  <v-edit-dialog :return-value.sync="item.Note" large lazy persistent @save="saveItem({'ID': item.ID, 'Note': item.Note})">
                      <v-tooltip top right max-width="250">
                          <template v-slot:activator="{ on }">
                              <span v-on="on">{{ item.Note }}</span>
                          </template>
                          <span>{{ item.Note }}</span>
                      </v-tooltip>
                      <template v-slot:input>
                          <v-textarea v-model="item.Note" label="Ghi chú" single-line autofocus></v-textarea>
                      </template>
                  </v-edit-dialog>
                </td>
                <td class="text-center" v-if="!priceId">
                  <!-- <v-icon class="ma-1" v-if="roleEditable" medium @click="deleteItem(item.ID)">fa-trash-alt</v-icon> -->
                  <a target="_blank" :href="'quan-ly-khach-hang/' + item.ID"><i role="button" medium="" class="v-icon notranslate ma-1 v-icon--link theme--light" v-bind:class="roleEditable ? 'fa fa-edit' : 'fa fa-external-link-alt'"></i></a>
                </td>
                <td class="text-center" v-else>
                  <v-icon medium @click="unlinkItem(item.ID)">fa-unlink</v-icon>
                </td>
              </tr>
            </template>
            <template slot="no-data">
              <span>
                <p class="pt-2">Không có dữ liệu</p>
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import { sendErrorNotice } from "@/commons/utils";
import _ from "lodash";

export default {
  components: {
    "hpo-filter": Filtering
  },
  props: ["priceId"],
  data() {
    let dataType = configs.DATA_TYPE;
    return {
      search: "",
      headers: [
        {
          text: "ID",
          value: "ID",
          filterable: true,
          dataType: dataType["Number"]
        },
        {
          text: "User name",
          value: "Username",
          filterable: true,
          quickSearch: true,
          dataType: dataType["String"]
        },
        {
          text: "Họ Tên",
          value: "LastName",
          quickSearch: true,
          filterable: true,
          dataType: dataType["String"]
        },
        {
          text: "Mã khách",
          value: "CustomerCode",
          filterable: true,
          quickSearch: true,
          dataType: dataType["String"]
        },
        {
          text: "SĐT",
          value: "PhoneNumber",
          filterable: true,
          quickSearch: true,
          dataType: dataType["String"]
        },
        {
          text: "Bảng giá",
          value: "PriceNames",
          filterable: true,
          dataType: dataType["String"]
        },
        {
          text: "Số dư",
          value: "Wallet",
          align: 'end',
          filterable: true,
          decimal: 0,
          dataType: dataType["Number"]
        },
        {
          text: "CSKH",
          value: "CustomerServiceName",
          quickSearch: true,
          filterable: true,
          dataType: dataType["String"]
        },
        {
          text: "Sale",
          value: "SaleName",
          quickSearch: true,
          filterable: true,
          dataType: dataType["String"]
        },
        {
          text: "Ghi chú",
          value: "Note",
          quickSearch: true,
          filterable: true,
          dataType: dataType["String"]
        },
        {
          text: "Thao tác",
          value: "",
          align: "center",
          sortable: false,
          filterable: false
        }
      ],
      customer_status: configs.CUSTOMER_STATUS_LIST,
      pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
      items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
      viewName: "customer_list",
      filterConditions: [],
      quickFilterConditions: []
    };
  },
  computed: {
    ...mapState({
      items: state => state.customer.all.data,
      total_rows: state => state.customer.all.total,
      loading: state => state.customer.loading,
      staffs: state => state.commons.all_users,
      all_prices: (state) => state.prices.all.data,
      puchase_staffs: state =>
        _.filter(state.commons.all_users, function(o) {
          return (o.RoleType & configs.ROLE_TYPE.CustomerService) == configs.ROLE_TYPE.CustomerService;
        }),
      cs_staffs: state =>
        _.filter(state.commons.all_users, function(o) {
          return (o.RoleType & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase;
        })
      }),
    roleEditable() {
      return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
    },
    roleCS() {
      return (
        (this.$store.state.authentication.user.roletype &
          configs.ROLE_TYPE.Purchase) ==
        configs.ROLE_TYPE.Purchase
      );
    }
  },
  watch: {
    pagination: {
      handler: function(val, oldVal) {
        let filterConditions = this.$store.state.commons.filterConditions;
        this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
        this.filter_data();
      },
      deep: true
    },
    roleEditable() {
      this.$root.$emit('REQUEST_ROOT_OPTION', {invisibleCreateButton: !this.roleEditable});
    }
  },
  methods: {
    filter_data: function() {
      var param = {
        pagination: this.pagination,
        search_term: this.search,
        filter: this.filterConditions,
        quickFilter: this.quickFilterConditions
      };
      if (this.priceId) {
        param.filter.push({
          column: "PriceIDs",
          value: this.priceId,
          condition: "find_in_set",
        });
      }
      this.$store.dispatch("customer/getCustomerList", param);
    },
    gotoDetail: function(id) {
      this.$router.push({
        name: "CustomerDetail",
        params: {
          Pid: id,
          title: `Khách hàng (#${id})`
        }
      });
    },
    gotoWithDraw: function(username) {
      this.$router.push({
        name: "WithdrawHistory",
        query: {
          'username': username,
        }
      });
    },
    gotoRecharge: function(username) {
      this.$router.push({
        name: "RechargeHistory",
        query: {
          'username': username,
        }
      });
    },
    // deleteItem: function (id) {
    //   window.getApp.$emit("REQUEST_DELETE_DATA", {'id': id, 'viewName': this.viewName});
    // },
    unlinkItem: function(id) {
      this.$store.dispatch("customer/setCustomerDetail", {
        'data': {PriceID: 0},
        'id': id
      });
    },
    get_status_color(val, enums) {
      return this.$options.filters.display_color(val, enums);
    },
    applyFilter(filterConditions) {
      let sortBy = this.pagination.sortBy
      let sortDesc = this.pagination.sortDesc
      this.pagination = configs.PAGINATION;
      this.pagination.sortBy = sortBy;
      this.pagination.sortDesc = sortDesc;
      this.filterConditions = filterConditions;
      this.filter_data();
    },
    quickFilter(filterConditions) {
      this.quickFilterConditions = filterConditions;
      this.filter_data();
    },
    createData() {
      if(this.roleEditable) {
        this.$router.push({
          name: "CustomerDetail",
          params: {
            Pid: 'tao-moi',
            title: `Tạo mới khách hàng`
          }
        });
      }
      else {
        sendErrorNotice("Bạn không có quyền tạo mới khách hàng!");
      }
    },
    getStaffName(staffId) {
      const staff = _.find(this.staffs, {
        ID: staffId
      });
      return staff ? staff.FullName : "";
    },
    saveItem(item) {
      this.$store.dispatch("customer/setCustomerDetail", {
        data: {...item},
        id: item.ID
      });
    },
  },
  mounted() {
    this.$store.dispatch("commons/getAllUsers");
    this.$store.dispatch("prices/getList");
    this.$root.$emit('REQUEST_ROOT_OPTION', {invisibleCreateButton: !this.roleEditable});
    this.$root.$on("REQUEST_CREATE_DATA", this.createData);
    this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    // this.$root.$on("CONFIRMED_REMOVE_DATA", item => {
    //   if(item.viewName == this.viewName) {
    //     this.$store.dispatch("customer/removeCustomerDetail", item.id);
    //   }
    // });
  },
  beforeMount() {
    this.$store.commit("customer/setCustomerList", {data: []})
  },
  beforeDestroy() {
    this.$root.$off("REQUEST_CREATE_DATA", this.createData);
    this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    // this.$root.$off("CONFIRMED_REMOVE_DATA");
  }
};
</script>
<style scoped>
a {
    text-decoration: none !important;
}
</style>